class SlideText {
  constructor() {

  }
  init() {
    $('.swiper-button-prev, .swiper-button-next, .swiper-pagination-bullet').on('click', () => {
      this.changeText();
    });
  }
  changeText() {
    setTimeout(() => {
      let index = $('.swiper-slide.swiper-slide-active').index('.swiper-slide');
      $('.p-beverageSec4__txtWrap').removeClass('is-active');
      switch(index % 5) {
        case 0:
          $('.p-beverageSec4__txtWrap--5').addClass('is-active');
          break;
        case 1:
          $('.p-beverageSec4__txtWrap--1').addClass('is-active');
          break;
        case 2:
          $('.p-beverageSec4__txtWrap--2').addClass('is-active');
          break;
        case 3:
          $('.p-beverageSec4__txtWrap--3').addClass('is-active');
          break;
        case 4:
          $('.p-beverageSec4__txtWrap--4').addClass('is-active');
          break;
      }
    }, 100);
  }
}
export default SlideText;