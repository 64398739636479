/**
 * import Vue
 * ※vueを使用しない場合は削除する
 */
// import App from './vue/App.js';

/**
 * import node_modules
 */
import 'desvg';
import objectFitImages from 'object-fit-images';
import picturefill from 'picturefill';
import SmoothScroll from 'smooth-scroll';

/**
 * import modules
 */
import Sample from './modules/sample';
import Common from './modules/common';
import Ham from './modules/ham';
import Header from './modules/header';
import Drop from './modules/drop';
import Slide from './modules/slide';
import Tab from './modules/tab';
import SlideText from './modules/slideText';
import Video from './modules/video';
import Wave from './modules/wave';

/**
 * Mainクラス
 * @constructor
 */
class Main {
  constructor() {
    this.setup();
  }
  /**
   * インスタンス生成時に実行する処理
   */
  setup() {
    document.addEventListener('DOMContentLoaded', () => {
      this.created();

    })
    window.addEventListener('load', () => {
      this.mounted();
    });
  }
  /**
   * ページ読み込み時に実行する処理
   */
  created() {
    picturefill();

    const sample = new Sample();
    sample.init();

    const common = new Common();
    common.init();

    const ham = new Ham();
    ham.init();

    const header = new Header();
    header.init();

    const drop = new Drop();
    drop.init();

    const slide = new Slide();
    slide.init();

    const wave = new Wave();
    wave.init();

    if($('#beverage').length > 0) {
      const tab = new Tab();
      tab.init();

      const slideText = new SlideText();
      slideText.init();

      const video = new Video();
      video.init();
    }
  }
  /**
   * 画像読み込み後に実行する処理
   */
  mounted() {


    this.init_deSVG('.js-desvg');

    this.init_objectFitImages('.object-fit');

    this.init_smoothScroll();
  }



  /**
   * ライブラリ/deSVG
   * インラインsvgをsvgタグに変換
   * https://github.com/benhowdle89/deSVG
   * @param {Element} target ターゲットとなる要素
   */
  init_deSVG(target) {
    deSVG(target, true)
  }

  /**
   * ライブラリ/object-fit-images
   * css object-fit のポリフィル
   * https://github.com/fregante/object-fit-images
   * @param {Element} target ターゲットとなる要素
   */
  init_objectFitImages(target) {
    objectFitImages(target);
  }

  /**
   * ライブラリ/picturefill
   * html pictureタグのポリフィル
   * https://scottjehl.github.io/picturefill/
   */
  init_picturefill() {
    picturefill();
  }

  /**
   * ライブラリ/smooth-scroll
   * スムーススクロール
   * https://github.com/cferdinandi/smooth-scroll
   */
  init_smoothScroll() {
    /**
     * ターゲットとなる要素
     * @type {Element}
     */
    const targetElement = 'a[href*="#"]';

    /**
     * オプション
     * @type {object}
     */
    const option = {
      speed: 1000,
      easing: 'easeInOutCubic',
      speedAsDuration: true,
      updateURL:false,
      //header: '.l-header' 固定ヘッダーの場合
    }

    new SmoothScroll(targetElement, option);
  }
}

export default new Main();
