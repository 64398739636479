class Video {
  constructor() {

  }
  init() {
    $('.js-video').on('click', e => {
      this.playVideo(e);
    });
    $('video').on('playing', e => {
      this.activeClass(e);
    });
    $('video').on('pause', e => {
      this.passiveClass(e);
    });
  }
  playVideo(e) {
    if($(e.target).parent().hasClass('is-active')) {
      $(e.target).get(0).pause();
    } else {
      $(e.target).get(0).play();
    }
  }
  activeClass(e) {
    $(e.target).parent().addClass('is-active');
  }
  passiveClass(e) {
    $(e.target).parent().removeClass('is-active');
  }
}
export default Video;