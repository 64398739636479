/**
 * コモンクラス
 * @constructor
 */
 class Ham {
    constructor() {
  
    }
    /**
     * コモン
     */
    init() {
        $('.js-hmg').on('click',function(e) {
            e.preventDefault();
            $(this).toggleClass('isactive');
            $('.m-ham').slideToggle();
            $('.l-headerTopSp').toggleClass('isactive');
        })
    }
}
export default Ham;