/**
 * コモンクラス
 * @constructor
 */
 class Header {
    constructor() {
  
    }
    /**
     * コモン
     */
    init() {
      

        $(function(){
            $(window).scroll(function (){
                $('#news').each(function(){
                    var position = $(this).offset().top;
                    var scroll = $(window).scrollTop();
                    var windowHeight = $(window).height();
                    if (scroll > position - windowHeight +500){
                      $('.l-headerTopPc').addClass('headerWide')
                      $('.l-headerTopSp').addClass('headerWide')
                    }else {
                        $('.l-headerTopPc').removeClass('headerWide')
                        $('.l-headerTopSp').removeClass('headerWide')
                    }
                });
            });
        });

        let urlParam = document.URL;
        if(urlParam.match(/contact/)) {
            $('.contact').addClass('active')
        }
        if(urlParam.match(/about/)) {
            $('.about').addClass('active')
        }
        if(urlParam.match(/development/)) {
            $('.development').addClass('active')
        }
        if(urlParam.match(/products/)) {
            $('.products').addClass('active')
        }
        if(urlParam.match(/factory/)) {
            $('.factory').addClass('active')
        }
        if(urlParam.match(/sitemap/)) {
            $('.sitemap').addClass('active')
        }
    }
}

export default Header;