/**
 * コモンクラス
 * @constructor
 */
class Common {
  constructor() {

  }
  /**
   * コモン
   */
  init() {
    $(function () {
      var target1 = $("#parallax-01");
      var targetPosOT1 = target1.offset().top;
      var targetFactor = 0.5;
      var windowH = $(window).height();
      var scrollYStart1 = targetPosOT1 - windowH;

      $(window).on('scroll', function () {
          var scrollY = $(this).scrollTop();
          if (scrollY > scrollYStart1) {
              target1.css('background-position-y', (scrollY - targetPosOT1) * targetFactor + 'px');
          }
      });
  });
  
  
  $('.btnWrap01').on('mouseover',function() {
    $('.prod_img--01').addClass('btn_on')
  })
  $('.btnWrap02').on('mouseover',function() {
    $('.prod_img--02').addClass('btn_on')
  })
  $('.btnWrap01').on('mouseout',function() {
    $('.prod_img--01').removeClass('btn_on')
  })
  $('.btnWrap02').on('mouseout',function() {
    $('.prod_img--02').removeClass('btn_on')
  })

  $(document).on("change", '#name,#ruby,#postal_code,#address,#e-mail,#tel,#company,#dept', function(){
    let str = $(this).val()
    str = str.replace(/[０-９]/g, function(s) {
        return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
    });
    $(this).val(str);
  });
  }
}

export default Common;
