/**
 * コモンクラス
 * @constructor
 */
 class Drop {
    constructor() {
  
    }
    /**
     * コモン
     */
    init() {
        $('.js-drop').on('click',function(e) {
            e.preventDefault();
            $('.p-news__dropDownMenu').slideToggle();
        })
        function drop(menu,item) {
            $(menu).on('click',function(e) {
                e.preventDefault();
                document.getElementById("js-drop").innerText = this.innerText;
                $('.item').hide();
                $(item).show();
                $('.p-news__dropDownMenu').slideUp();
            })
        }

        drop('.menu2022','.year2022')
        drop('.menu2021','.year2021')
    }
}

export default Drop;