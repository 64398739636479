/**
 * サンプルクラス
 * @constructor
 */
 class Wave {
    constructor() {
    }
    /**
     * サンプル
     */
    init() {
        
    }
  };
  
  export default Wave;