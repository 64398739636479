class Tab {
    constructor() {

    }
    init() {
      $('.js-tab').on('click', e => {
        this.changeTab(e);
        this.changeBg(e);
      });
    }
    changeTab(e) {
      let index = $('.p-beverageSec3Tab__list .js-tab').index($(e.currentTarget));
      $('.js-tab, .p-beverageSec3TabBox').removeClass('active');
      $(e.currentTarget).addClass('active');
      $('.p-beverageSec3TabBox').eq(index).addClass('active');
    }
    changeBg(e) {
      let index = $('.p-beverageSec3Tab__list .js-tab').index($(e.currentTarget));
      if(index === 1) {
        $('.p-beverageSec3').addClass('is-change');
      } else {
        $('.p-beverageSec3').removeClass('is-change');
      }
    }
}
export default Tab;