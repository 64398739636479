import { nodeName } from "jquery";
import Swiper from "swiper";
/**
 * コモンクラス
 * @constructor
 */
 class Slide {
    constructor() {

    }
    /**
     * コモン
     */
    init() {
        
      if($('#factory').length > 0) {
        // 工場ページスライド
          let mySwiper = new Swiper(".swiper-container", {
              spaceBetween: 50,
              touchRatio :0,
              loop:true,
              
              navigation: {
                  nextEl: '.m-slide__next01',
                  prevEl: '.m-slide__prev01',
              },
              pagination: {
                  el: '.m-slide__pagenation01',
                  type: 'bullets',
                  clickable: true,
              },
              breakpoints: {
                  750: {
                      touchRatio :1,
                  }
              }
          });

          // スライドのカウント処理
          let currentNum = 1;
          document.getElementById('js-count').innerText = currentNum;
          $('.m-slide__next01').on('click',function(e) {
              e.preventDefault()
              currentNum ++;
              document.getElementById('js-count').innerText = currentNum;
              console.log(currentNum)
              if(currentNum === 10) {
                  currentNum = 0;
              }
          })
          $('.m-slide__prev01').on('click',function(e) {
              e.preventDefault()
              currentNum --;
              document.getElementById('js-count').innerText = currentNum;
              console.log(currentNum);
              if(currentNum === 0) {
                  currentNum = 10;
                  document.getElementById('js-count').innerText = currentNum;
              }
          })
        }

      if($('#beverage').length > 0) {
        // beverage-businessページスライド
            let mySwiper = new Swiper(".swiper-container", {
              spaceBetween: 30,
              touchRatio :0,
              loop: true,
              
              navigation: {
                nextEl: '.p-beverageSec4Slider__next',
                prevEl: '.p-beverageSec4Slider__prev',
            },
              pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            },
              breakpoints: {
                  750: {
                      touchRatio :1,
                  }
              }
            });
      }
    }
}
export default Slide;